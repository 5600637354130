import Image from 'next/image';
import Tweet from 'components/Tweet/Tweet';

export default function Thread({ image, content }) {
  return (
    <div className={`shadow bg-white bg-opacity-20 rounded-2xl sm:w-9/12 mx-auto`}>
      <div className="px-6 pt-6 pb-2 flex flex-col">
        <div className="flex items-center">
          <div className="rounded-full h-8 md:h-12 w-8 md:w-12 relative overflow-hidden">
            <Image src={image} layout='fill' />
          </div>
          <div className="ml-2">
            <div>
              <h6 className="font-sans font-bold text-sm sm:text-base inline text-black">whoisli.am</h6>
            </div>
            <div className="-mt-2 sm:-mt-1">
              <h6 className="font-sans font-light text-sm sm:text-base inline text-gray-600">@liamptfarrelly</h6>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col mt-2 space-y-2">
          <p className="font-sans text-sm sm:text-base">{content}</p>
          <p className="font-sans text-xs text-gray-600">8:26 PM · Mar 6, 2022 · Twitter Web App</p>
        </div>
      </div>
      <div className="relative pb-2">
        <div className="absolute z-0 bg-gray-400 opacity-20 w-[2px] sm:w-1 h-[84%] sm:h-[86%] md:h-[88%] top-[36px] left-[35px] sm:left-[38px] md:left-[45px]"></div>
        <Tweet 
          image={image} 
          content={'to get started, you need to sign up with your twitter account. this gives proofin.bio credentials to use the twitter api on your behalf.'} 
          isRight={true}
        />
        <Tweet 
          image={image} 
          content={'the credentials allow full read + write access to your account, but proofin.bio will only update your profile photo — just like @profilepeacock and thisprofiledoesnotexist.com'} 
        />
        <Tweet 
          image={image}
          content={<>once you've connected your twitter, you can connect your wallet using walletconnect.<br/><br/>we'll load in all of the nfts in your wallet, and you can choose your favourites… and that's it.</>}
        />
        <Tweet 
          image={image}
          content={<>we'll regularly cycle through your chosen nfts and update your profile picture.<br/><br/>now you can prove ownership by linking to your proofin.bio.</>}
          isRight={true}
        />
        <Tweet 
          image={image}
          content={<>wagmi.<br/>/end thread</>}
          isRight={true}
        />
      </div>
    </div>
  );
}