import Carousel from 'components/Carousel/Carousel';

export default function LeadCard({ images }) {
  return (
    <div className="shadow bg-white bg-opacity-20 rounded-2xl p-8 mx-auto w-full">
      <div className="flex flex-col">
        <div className="rounded-full h-20 md:h-24 w-20 md:w-24 relative overflow-hidden fix-radius">
          <Carousel images={images} />
        </div>
        <div className="flex-1 py-1">
          <div className="h-2 md:h-3 mt-2 w-24 md:w-32 bg-white opacity-20 rounded-lg"></div>
          <div className="h-2 md:h-3 mt-2 w-32 md:w-48 bg-white opacity-20 rounded-lg"></div>
          <div className="space-y-3 mt-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="h-2 md:h-3 bg-white opacity-20 rounded-lg col-span-2"></div>
              <div className="h-2 md:h-3 bg-white opacity-20 rounded-lg col-span-1"></div>
            </div>
            <div className="h-2 md:h-3 bg-white opacity-20 rounded-lg"></div>
          </div>
        </div>
      </div>
    </div>
  );
}