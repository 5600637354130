import Head from 'components/Head/Head';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import Waitlist from 'components/Waitlist/Waitlist';
import Thread from 'components/Thread/Thread';
import LeadCard from 'components/LeadCard/LeadCard';

const IMAGES = Object.freeze([
  '/cryptopunk.jpeg',
  '/bayc.png',
  '/spacepogger.png',
  '/cool-cats.png',
  '/invisible-friend.png',
  '/doodles.png',
  '/soras.png'
]);

export default function Home() {
  return (
    <>
      <Head prefetch={IMAGES.slice(1)} />
      <div className="w-full h-full custom-bg">
        <div className="h-full max-w-2xl mx-auto">
          <div className="min-h-full w-full mx-auto flex flex-col">
            <Header />
            <div className="flex flex-col justify-center content-center items-center h-full flex-1 py-8 px-4 space-y-12 sm:space-y-20 mx-auto">
              <LeadCard images={IMAGES.slice(0,-1)} />
              <div className="w-full">
                <h1 className="-ml-2 font-sans font-extrabold mb-4 text-center text-2xl sm:text-4xl md:text-5xl">your twitter <span aria-label="handshake">🤝</span> your wallet</h1>
                <div className="mx-auto text-center w-full">
                  <p className="font-sans font-extralight md:text-lg mb-2">sync your nfts to your profile picture and prove ownership with proofin.bio.</p>
                  <p className="font-sans font-extralight md:text-lg mb-2"><i>no twitter blue subscription necessary.</i></p>
                </div>
              </div>
              <div className="w-full">
                <h3 className="text-center font-sans font-bold text-lg sm:text-xl mb-4">
                  <span aria-label="sparkle" className="text-lg md:text-2xl">✨</span>
                  {' '}join the waitlist for early access{' '}
                  <span aria-label="sparkle" className="text-lg md:text-2xl">✨</span>
                </h3>
                <Waitlist className="flex flex-col mx-auto w-9/12" />
              </div>
              <div className="w-full space-y-6">
                <h3 className="text-center font-sans font-bold text-lg sm:text-xl mb-4">
                  <span aria-label="lightning" className="text-lg md:text-2xl">⚡️</span>
                  {' '}how it works{' '}
                  <span aria-label="lightning" className="text-lg md:text-2xl">⚡️</span>
                </h3>
                <Thread 
                  image={IMAGES[IMAGES.length-1]} 
                  content={<>have been getting some questions recently on how proofin.bio works. a short 🧵:</>} 
                />
              </div>
              <div className="w-full">
                <h3 className="text-center font-sans font-bold text-lg sm:text-xl mb-4">
                  <span aria-label="sparkly heart">💖</span>
                  {' '}you're still early{' '}
                  <span aria-label="sparkly heart">💖</span>
                </h3>
                <Waitlist className="flex flex-col mx-auto w-9/12" />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  )
}
