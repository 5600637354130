import Image from 'next/image';
import { motion } from 'framer-motion';

export default function Tweet({ image, content }) {
  return (
    <motion.div 
      whileInView={{ scale: 1 }}
      initial={{ scale: 0.9 }}
      transition={{ duration: 0.2 }}
      className={`px-6 py-4`}
      viewport={{ once: true }}
    >
      <div className="flex">
        <div className="rounded-full h-6 sm:h-8 md:h-12 w-6 sm:w-8 md:w-12 relative overflow-hidden z-10">
          <Image src={image} layout='fill' />
        </div>
        <div className="flex-1 flex flex-col">
          <div className="ml-2">
            <h6 className="font-sans font-bold text-sm sm:text-base inline text-black">whoisli.am</h6>
            <h6 className="ml-1 font-sans font-light text-sm sm:text-base inline text-gray-600">@liamptfarrelly</h6>
          </div>
          <div className="ml-2 text-sm sm:text-base">
            <p className="font-sans">{content}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
}