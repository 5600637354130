import { useState } from 'react';
import ArrowRight from 'components/Icons/ArrowRight';
import Check from 'components/Icons/Check';
import Spinner from 'components/Icons/Spinner';
import { string } from 'yup';

const FORM_STATES = Object.freeze({
  DEFAULT: 'default_state',
  INVALID: 'invalid_entry_state',
  LOADING: 'loading_state',
  COMPLETE: 'completed_state',
});


export default function Waitlist(props) {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState(FORM_STATES.DEFAULT);

  async function addToWaitlist() {
    try {
      await string().email().required().validate(email);
      setStatus(FORM_STATES.LOADING);
      if(window?.plausible) {
        window?.plausible('join-waitlist-click-index');
      }
      await fetch('/api/waitlist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email
        })
      });
      setStatus(FORM_STATES.COMPLETE);
    } catch (_) {
      setStatus(FORM_STATES.INVALID);
    }
  }

  function updateEmail(e) {
    setStatus(FORM_STATES.DEFAULT);
    setEmail(e.target.value);
  }

  return (
    <div {...props}>
      <div className="flex">
        <input 
          type="email" 
          value={email} 
          onChange={updateEmail} 
          className={`email-input rounded-l-xl w-full bg-white bg-opacity-30 py-2 px-6 text-black placeholder-indigo-200 ${FORM_STATES.INVALID === status && 'border-2 border-red-300'}`}
          placeholder="vitalik@buterin.eth" 
        />
        <button className="px-4 bg-[#67f] text-white rounded-r-xl font-sans font-bold flex-1" disabled={status !== FORM_STATES.DEFAULT} onClick={addToWaitlist}>
          {(FORM_STATES.DEFAULT === status || FORM_STATES.INVALID === status) && (<ArrowRight />)}
          {FORM_STATES.LOADING === status && (<Spinner />)}
          {FORM_STATES.COMPLETE === status && (<Check />)}
        </button>
      </div>
      <div className="h-2">
        {FORM_STATES.INVALID === status && (<small className="font-sans font-light text-red-500 mt-2">this must be a valid email address.</small>)}
      </div>
    </div>
  )
}