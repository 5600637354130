import Link from 'next/link';

export default function Footer() {
  return (
    <div className="flex pt-8 pb-4 px-4 justify-between items-center flex-col w-9/12 text-xs md:text-sm mt-auto mx-auto">
      <p className="font-sans font-bold text-white mb-2">made of optimism by <Link href="https://twitter.com/liamptfarrelly"><a className="underline">@liamptfarrelly</a></Link></p>
      <div className="flex text-white font-sans opacity-80">
        <p>
          <Link href="/legal/terms-of-service"><a className="underline">terms of service</a></Link>
          <span className="pointer-events-none" aria-hidden>{' '}/{' '}</span>
          <Link href="/legal/privacy-policy"><a className="underline">privacy policy</a></Link>
        </p>
      </div>
    </div>
  )
}