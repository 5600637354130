import { default as NextHead } from 'next/head'

export default function Head({ pageName, prefetch }){
  const pageTitle = `proofin.bio${pageName ? ' | ' + pageName : ''}`;
  return (
    <NextHead>
      <title>{pageTitle}</title>
      <link href="/bg_gradient.png" rel="icon" type="image/x-icon" />
      <script defer data-domain="proofin.bio" src="https://plausible.io/js/plausible.js"></script>
      <meta name="description" content="sync your twitter profile picture to the nfts in your wallet" />
      <meta name="keywords" content="crypto,web3,nft,twitter,profile picture,pfp,nfts,wallet,sync,connect" />
      <meta name="twitter:card" value="summary_large_image" />
      <meta name="twitter:site" content="@liamptfarrelly" />
      <meta name="twitter:title" content="proofin.bio" />
      <meta property="og:title" content="proofin.bio" />
      <meta name="twitter:description" content="sync your nfts to your twitter profile picture" />
      <meta property="og:description" content="sync your nfts to your twitter profile picture" />
      <meta name="twitter:creator" content="@liamptfarrelly" />
      <meta name="twitter:image" content="https://proofin.bio/gradient.png" />
      <meta property="og:image" content="https://proofin.bio/gradient.png" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      {prefetch && prefetch.map((src) => (<link key={src} href={src} rel="prefetch" />))}
    </NextHead>
  );
}