import { 
  useEffect, 
  useState, 
} from 'react';
import { 
  AnimatePresence,
  motion
} from "framer-motion"

const variants = {
  enter: {
    x: '-100%',
  },
  center: {
    zIndex: 1,
    x: '0%',
  },
  exit: {
    zIndex: 0,
    x: '100%',
  }
};

export default function Carousel({ images }){
  const [index, setIndex] = useState(0)

  const handleNext = () => {
    setIndex((index + 1) % images.length);
  }

  useEffect(() => {
    const timer = setInterval(() => handleNext(), 2000)
    return () => clearInterval(timer)
  }, [handleNext])

  return (
    <AnimatePresence initial={false}>
      <motion.img
        initial="enter"
        animate="center"
        exit="exit"
        variants={variants}
        key={images[index]}
        src={images[index]}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className="absolute"
      />
    </AnimatePresence>
  )
}
